a {
  color: #2350a2;
}
a:hover {
  color: #041b44;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}
.App {
  height: calc(100vh - 20px);
  text-align: center;
  background-color: white;
  margin: 10px;
  background-image: url("./assets/headbackground.png");
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: bottom 10px;
  background-size: contain;
}
.Menu {
  padding-top: 10px;
  margin-top: 10px;
  color: #2350a2;
  font-size: 12px;
}
.Box {
  width: 30px;
  height: 10px;
  background-color: #2350a2;
  color: white;
  clip-path: polygon(0% 0%, 80% 0%, 100% 50%, 80% 100%, 0% 100%);
}

.Submenu {
  padding-right: 30px;
  font-size: small;
}

.column {
  width: 100%%;
  /* Should be removed. Only for demonstration */
}

/* Clear floats after the columns */
.row {
}

.button {
  background-color: #2a99f3;
  border: none;
  color: white;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 25px;
  margin: 4px 2px;
  border-radius: 20px;
}
.button:hover {
  background-color: #6ab1eb;
  border: none;
  color: white;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 25px;
  margin: 4px 2px;
  border-radius: 20px;
}

.footertext {
  margin-top: 0px;
  margin-top: 0px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: transparent;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

  color: black;
  text-align: center;
}
.col1data {
  margin-top: 30px;
}
@media only screen and (min-width: 768px) {
  /* For desktop: */
  .Submenu {
    padding-right: 30px;
    font-size: 30px;
  }

  .column {
    float: left;
    width: 50%;
    height: 400px;

    /* Should be removed. Only for demonstration */
  }

  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  .App {
    box-sizing: border-box;
    height: calc(100vh - 140px);
    text-align: center;
    background-color: white;
    margin: 70px;

    background-image: url("./assets/headbackground.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: bottom 10px;
    background-size: contain;
  }

  .button {
    background-color: #2a99f3;
    border: none;
    color: white;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    margin: 4px 2px;
    border-radius: 20px;
  }
  .button:hover {
    background-color: #6ab1eb;
    border: none;
    color: white;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    margin: 4px 2px;
    border-radius: 20px;
  }

  .footertext {
    align-items: center;
    margin-bottom: 70px;
    margin-left: 70px;
    margin-right: 70px;
    background-color: transparent;
    position: fixed;

    text-align: center;

    bottom: 0;
    color: black;
  }
  .col1data {
    margin-top: 170px;
  }
}
